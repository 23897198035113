<template>
  <a-spin :spinning="false">
<!--  <a-modal-->
<!--      :title="'Möchten Sie sich ausloggen?'"-->
<!--      v-model:open="logout"-->
<!--      :closable="true"-->
<!--      @cancel="logout = false"-->
<!--      @ok="logoutFunction()"-->
<!--      okText="Ok"-->
<!--      cancelText="Abbrechen"-->
<!--      centered>-->
<!--    </a-modal>-->
    <div class="headerContent">
      <div class="headerContainer">
        <div class="imageContainer">
          <a href="https://mitteldeutsche-it.de/"><img src="../assets/mittelDeutsche_header_banner2.svg" width="230" height="45" style="width: 100%; max-width: 100%;"></a>
        </div>
        <div class="menuContainer">
          <a-menu class="menu" v-model:selectedKeys="current" mode="horizontal" :items="items" @select="handleClick" triggerSubMenuAction="click"></a-menu>
        </div>

        <div class="settingsContainer">
          <a-button class="settingsButton" :size="windowWidth > 974 ? 'large' : 'default'" shape="circle" @click="callSettings()"><SettingOutlined/></a-button>
        </div>      
        <div class="logoutContainer">
          <a-button class="logoutButton" :size="windowWidth > 974 ? 'large' : 'default'" type="primary" @click="logoutFunction()">Ausloggen</a-button>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script setup>

  //IMPORTS
  import { ref, reactive, computed, onMounted, onBeforeUnmount } from 'vue'
  import { useRouter } from 'vue-router';
  import api from '@/apis/apis'
  import { SettingOutlined } from '@ant-design/icons-vue'
  import store from '@/store'

  //VARIABLES
  const windowWidth = ref(window.innerWidth)
  const logout      = ref(false)
  const items       = reactive([
    { key: '0', label: 'Dashboard', title: 'Dashboard', path: '/dashboard' },
    { key: '1', label: 'Customers', title: 'Customers', path: '/customers' },
    { key: '2', label: 'Calculator', title: 'Calculator', path: '/prices' },
    { key: '3', label: 'Invoices', title: 'Invoices', path: '/invoices' },
    { key: '4', label: 'Tutorials', title: 'Tutorials', path: '/tutorials' },
  ])

  const router = useRouter()

  //COMPUTED PROPERTIES
  const currentMenuTab = computed(() => store.getters.currentMenuTab)
  const current = computed({
    get() {
      return currentMenuTab.value;
    },
    set(value) {
      setCurrentMenuTab(value);
    }
  })

  //Lifecycle Hooks
  onMounted(() => {
    router.push({ path: '/dashboard' })
    window.addEventListener('resize', onResize)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize)
  })

  //FUNCTIONS
  function handleClick(e) {
    router.push({ path: e.item.originItemValue.path })
  }

  function onResize() {
    windowWidth.value = window.innerWidth;
  }

  async function logoutFunction() {
    logout.value = true;

    try {
      const response = await api.logout()

      if (response.status === 200) {
        document.cookie = "account=; SameSite=None; Secure"
        document.cookie = "authenticated=false; SameSite=None; Secure"
        document.cookie = "session_token=; SameSite=None; Secure"
        document.cookie = "sessionkey=; path=/client/api; SameSite=None; Secure"
        document.cookie = "JSESSIONID=; path=/client; SameSite=None; Secure"
        document.cookie = "userid=; SameSite=None; Secure"
        document.cookie = "PHPSESSID=; SameSite=None; Secure"
        document.cookie = "BILLING-JSESSIONID=; path=/; SameSite=None; Secure"

        window.location.href = "https://login.mitteldeutsche-it.de/"
      }

    } catch (error) {
      console.log(error)
      document.cookie = "account=; SameSite=None; Secure"
      document.cookie = "authenticated=false; SameSite=None; Secure"
    } 
  }

  function callSettings() {
    router.push({ path: '/settings' })
    setCurrentMenuTab(['-1'])
  }

  function setCurrentMenuTab(value) {
    store.commit("setCurrentMenuTab", value)
  }

</script>

<style scoped>

.headerContent {
  display: flex;
  align-items: center;
  position: sticky;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: white;
  z-index: 1000;
  top: 0px;
  left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.headerContainer {
  display: flex;
  height: 60%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.logoutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
}
.menuContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  margin-left: 40px;
  width: 600px;
}
.menu {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5em;
  color: #333;
}
.imageContainer {
  display: flex;
  width: 230px;
}
.settingsButton {
  border: none;
  outline: none;
  box-shadow: none;
  margin-right: 50px;
  height: 70px;
  width: 70px;
  font-size: 200%;
}
.logoutButton {
  font-weight: bold;
  border-radius: 4px;
  margin-right: 10px;
}
@media only screen and (max-width: 1026px) {
  .menuContainer {
    width: 530px;
    margin-right: 10px;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 919px) {
  .menuContainer {
    width: 450px;
  }
  .logoutContainer{
    padding-left: 0px;
  }
}
@media only screen and (max-width: 881px) {
  .menuContainer {
    width: 420px;
    margin-right: 20px;
  }
}
@media only screen and (max-width: 825px) {
  .menuContainer {
    width: 310px;
  }
}

@media only screen and (max-width: 715px) {
  .menuContainer {
    width: 300px;
    margin-left: 10px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 663px) {
  .menuContainer {
    width: 210px;
    margin-left: 10px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 615px) {
  .menuContainer {
    width: 180px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 530px) {
  .logoutContainer{
    width: 80px;
  }
  .menuContainer {
    width: 50px;
    margin-left: 10px;
    margin-right: 30px;
  }
  .dashboardContent {
    flex-direction: column;
  }
  .imageContainer {
    display: flex;
    width: 230px;
  }
  .logoutButton {
    font-weight: bold;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 400px) {
.logoutContainer{
    padding-left: 80px;
  }
}
 @media only screen and (min-width: 2400px) {
  .menuContainer {
    width: 600px;
    margin-left: 10px;
    margin-right: 40px;
  }
}
</style>
