<template>
  <div>
    <div class="panel-server">
      <div align-self="center">
        <img class="imageMargin" width="30" :src="getImgUrl(iconUrl)"/>
      </div>
      <div class="editAndDeleteServer">
        <div class="serverName"> {{ server.name }} </div>
        <div class="clickable" @click="editServer" style="width: 90px; display: flex; align-items: center;">
          <img class="buttonSize" src='../../assets/computer.svg'/>&nbsp;
          <span>Bearbeiten</span>
        </div>
        <div class="clickable" @click="deleteServerModal = true" style="width: 90px; display: flex; align-items: center;">
            <img class="buttonSize" src='../../assets/trash-2.svg'/>&nbsp;
          <span>Löschen</span>
        </div>
      </div>
      <a-modal
        :title="'Server löschen?'"
        v-model:open="deleteServerModal"
        :closable="true"
        @cancel="deleteServerModal = false"
        @ok="deleteConfig()"
        okText="Ok"
        cancelText="Abbrechen"
        centered>
        Wollen Sie den Server wirklich löschen?
    </a-modal>
    </div>
  </div>
</template>

<script setup>

//IMPORTS
import { ref, computed} from 'vue'
import store from '@/store'

//VARIABLES
const deleteServerModal = ref(false)
const getImgUrl = (pic) => require('../../assets' + pic)

//PROPS
const props = defineProps({
    server: {
      type: Object,
      required: true,
    }
  })

//COMPUTED PROPERTIES
const isSidebarOpen = computed(() => store.getters.isSidebarOpen)

const iconUrl = computed(() => {
  let url = ''

  const os = props.server.configuration.os;

  if (os.includes('windows') || os.includes('Windows')) {
    url = "/windows_icon.png"
  } else if (os === 'Linux') {
    url = "/linus.svg"
  } else {
    url = "/mitteldeutsche_logo_circle.svg"
  }

  const config = props.server.configuration

  if (config.MS_Exchange > 0) {
    url = "/exchange-new.svg"
  } else if (config.MSSQL_Standard_Core_License > 0 || config.MSSQL_Standard_User_License > 0) {
    url = "/microsoft-sql-server.png"
  }

  if (props.server.type === "containerIaaS") {
    url = "/container_single.jpeg"
  }

  return url
})

const serverTitle = computed(() => {
   let title = ""
   const os = props.server.configuration.os

   if (os === "linux") {
     title = "Linux "
   } else if (os.includes("win")) {
     title = "Win "
   }

   return title + props.server.name
})

//FUNCTIONS
function editServer(){
   if (isSidebarOpen.value) { return; }

   store.commit("setSelectedServer", props.server)
   store.commit("setSidebarVisibility", true);
}

function deleteConfig() {
   store.commit("deleteServer", props.server.id)

   if (isSidebarOpen.value === true) {
     store.commit("setSidebarVisibility", false)
     store.commit("resetServer")
   }
}
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}
.panel-server {
  font-size: 14px;
  display: flex;
  //background: url("../../assets/panel.svg") no-repeat;
  width: 170px;
  //height: 100px;
  background: #fff;
  border: 1px solid #3d7caf;
  border-bottom-width: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 8px;
  margin-bottom: 10px;
}

.serverName {
  word-break: break-word; 
  width: 120px; 
  height: auto; 
  margin-bottom: 5px; 
  margin-top: 5px;
  font-weight: 600;
  font-size: 14px;
}
.editAndDeleteServer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  text-align:left;
  font-size: 13px;
  .clickable {
    margin-bottom: 6px;
  }
}

.buttonSize {
  width: 14px;
}

.imageMargin {
  margin-top: 5px;
  margin-left: 5px;
}

@media (max-width: 1200px) {
  .panel-server {
    font-size: 13px;
    width: 150px;
    height: 100px;
    margin-left: 15px;
  }
  .editAndDeleteServer {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    text-align:left;
  }
  .serverName {
    width: 100px;
    margin-top: 10px;
  }
  .buttonSize {
    width: 16px;
  }
}

@media (max-width: 490px) {
  .panel-server {
    margin-left: 0px;
  }
}
</style>