<template>
  <div>
    <div v-if="serverType === 'cloudServer' || serverType === 'iaas'">
      <strong style="margin-bottom: 5px;display: block;">Betriebssystem</strong>
      <div>
        <a-select
         id="osSelect"
          v-model:value="os"
          style="width: 290px; margin-bottom: 10px;"
          @change="handleSelectChange"
        >
          <template v-for="item in osPriceList" :key="item.value">
            <a-select-option  :value="item.keyLabel">
              <span style="display: flex; align-items: center;">
                <img :src="item.icon" alt="" style="width: 20px; height: 20px; margin-right: 8px;" />
                  {{item.label}}
              </span> 
            </a-select-option>
          </template>
        </a-select>
        <template v-for="item in osPriceList" :key="item.value">
          <div v-if="os === item.keyLabel" style="background-color: #fff; color: #000; margin-bottom: 10px; border-radius: 6px;">
            <ConfigFormField
              v-for="(field, j) of filter(item.keyLabel)"
              :key="'option' + j"
              :options="field"
              :serverType="serverType"
            />
            <ConfigFormField
              :options="gpuPriceList"
              :serverType="serverType"
            />
          </div> 
        </template>
      </div>
    </div>
    <div v-if="serverType === 'wpCloud'" style="display: flex; flex-direction: column;">
      <br>
      <strong>Extra-Speicher(optional):</strong>
      <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span v-html="'Extra-SSD Storage in GB'"></span>&nbsp;
        </span>
        <a-input
          type="number"
          :class="['inputNumberField']"
          v-model:value="SSD"
          :min="0"
          :max="9899"
        ></a-input>
      </div>
      <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span v-html="'Extra-HDD Storage in GB'"></span>&nbsp;
        </span>
        <a-input
          type="number"
          :class="['inputNumberField']"
          v-model:value="HDD"
          :min="0"
          :max="9999"
        ></a-input>
      </div>
    </div>
  </div>
</template>

<script setup>

//IMPORTS
import ConfigFormField from "./ConfigFormField.vue";
import { ref, computed } from 'vue'
import store from '@/store'

//VARIABLES
var selectedRadio = ref('')

//PROPS
const props = defineProps({
  serverType: {
    type: String,
    required: true
  },
})

 //COMPUTED PROPERTIES
 const selectedServer = computed(() => store.getters.selectedServer)
 const priceList = computed(() => store.getters.getPriceList)
 
 const osPriceList = computed(() => {
  var filteredList = []
  var counter = 1 
  for (var [key, value] of store.getters.getPriceList) {
    if(value.isOS) {
      if(key == "Linux"){
        filteredList.push({value: counter, keyLabel: key, label: value.label, icon: require('@/assets/linus.svg')})
      }
      else {
        filteredList.push({value: counter, keyLabel: key, label: value.label, icon: require('@/assets/windows_icon.png')})
      }        
    counter++
    }
  }
  return filteredList
})

const gpuPriceList = computed(() => {
  const original = store.getters.getPriceList
  const clone = new Map(original)
  var gpuList = []
  var gpuListEntries = []
  for (var [key, value] of clone.entries()) {
    if (value.label.includes('GPU-')) {
      value.name = key
      gpuListEntries.push(value)
    }
  }
  gpuList[0] = 'GPUs'
  gpuList[1] = gpuListEntries
  return gpuList
})

const os = computed({
  get() {
    return selectedServer.value.configuration.os === '' ? 'Betriebssystem wählen' : selectedServer.value.configuration.os;
  },
  set(value) {
    let server = Object.assign({}, selectedServer.value);
    if (((server.configuration.os).toLowerCase()).includes("windows") === true) {
      server.configuration[server.configuration.os] = 0
    }
    server.configuration.os = value;
    if (Object.keys(selectedServer.value.configuration).length !== 0) {
      setSelectedConfiguration(server.configuration)
    }    
    if (((server.configuration.os).toLowerCase()).includes("windows") === true) {
      server.configuration[server.configuration.os] = 1
    }
  }
})

const SSD = computed({
  get() {
    return (selectedServer.value.configuration["SSD"]-100)
  },
  set(value) {
    if (value < 0 || value == "") {
      updateConfig("SSD", 100);
    } else if( value > 9899){
      updateConfig("SSD", 9999);
    } else {
      updateConfig("SSD", parseInt(value)+100);
    }
  }
})

const HDD = computed({
  get() {
    return selectedServer.value.configuration["HDD"]
  },
  set(value) {
    if (value > 9999) {
      updateConfig("HDD", 9999);
    } else {
      updateConfig("HDD", value);
    }
  }
})

//FUNCTIONS
function setSelectedConfiguration (configuration) {
    store.commit('setSelectedConfiguration', configuration)
  }

 function handleSelectChange(value) {
  let server = selectedServer.value
  server.configuration[selectedRadio.value] = ""
  selectedRadio.value = value

  if (((selectedRadio.value).toLowerCase()).includes("windows") === true) {
    if (server.configuration["vCPU"] > 0) {
      server.configuration[selectedRadio.value] = server.configuration["vCPU"]
      }
  }

  for (var item of priceList.value) {
    if ( item[1].isOS !== true && item[1].isResource !== true ) {
      server.configuration[item[0]] = 0
    }
  }      
}

function updateSelectedRadio (currentOS) {
  let server = selectedServer.value
  server.configuration[selectedRadio.value] = ""
  selectedRadio.value = currentOS

  if (((selectedRadio.value).toLowerCase()).includes("windows") === true) {
    if (server.configuration["vCPU"] > 0) {
      server.configuration[selectedRadio.value] = server.configuration["vCPU"]
    }
  }
    
  for (var item of priceList.value) {
    if ( item[1].isOS !== true && item[1].isResource !== true ) {
      server.configuration[item[0]] = 0
    }    
  }
}

function filter(osName) {
  const original = store.getters.getPriceList
  var clone = new Map(original)
  for (var [key, value] of clone.entries()) {
    if (value.isOS || value.isResource || value.label.includes('GPU')) {
      clone.delete(key)
    }
    if (value.label.includes('TSPlus Trial')){
      clone.delete(key)
    }
    if (osName === 'Linux') {
      if (value.label === 'LibreOffice Lizenz' || value.label.includes('Cyber Cloud')){
        continue
      }     
    }
    if (osName === 'Windows_11' || osName === 'Windows_10') {
      if (value.label.includes('LibreOffice Lizenz') || value.label.includes('MS Office') || value.label.includes('TSPlus') || value.label.includes('MSSQL') ||value.label.includes('Acronis') || value.label.includes('Cyber Cloud')){
        continue
      }    
    }
    if (!osName.includes('Server')) {
      clone.delete(key)
    }
  }
  return clone
}

function updateConfig(attribute, value) {
  let server = selectedServer.value;
  server.configuration[attribute] = value;
  setSelectedConfiguration(server.configuration);
}

</script>

<style lang="scss" scoped>
.inputNumberField {
  width: 60px;
}
/* .ant-radio-group {
  width: 100%;
  border-radius: 50px;
  background-color: rgb(233, 231, 231);
  margin-bottom: 5px;
} */
.ant-radio-button-wrapper{
  width: 100%;
  border-radius: 50px;
  background-color: rgb(233, 231, 231);
  margin-bottom: 5px;
}
.ant-radio-button-wrapper:hover{
  background-color: rgb(207, 205, 205);
}
.ant-radio-button {
  width: 100%;
  background-color: rgb(233, 231, 231);
  margin-bottom: 5px;
}
.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}
.os-buttons {
  width: 100%;
  .btn {
    border-radius: 50rem;
    width: 100%;
    margin: 0.25rem 0 !important;
    text-align: left;
    &.active {
      z-index: 0;
    }
    &,
    & > div {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.os-field {
  background-color: #fff;
  color: #000;
  margin-bottom: 10px;
}
</style>
