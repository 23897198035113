<template>
  <div>
    <div v-for="(server, index) in cloudServer" :key="index">
      <CloudServer 
        :serverId="server.id" 
        :isLast="index === cloudServer.length - 1" 
        :canCreateServer="canCreateServer" 
        @changeCreateServerButton="changeCreateServerButton" 
        @addServer="createNewServer"
        @deleteServerButton="deleteServerButton"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import CloudServer from "@/components/server/CloudServer.vue" 
import { useRouter } from 'vue-router'
import store from '@/store'

//VARIABLES
const canCreateServer = ref(true)
const router = useRouter()


//COMPUTED PROPERTIES
const serversByType = computed(() => store.getters.serversByType)
const cloudServer = computed(() => serversByType.value("cloudServer"))

//LIFECYCLE HOOKS
onMounted(() => {
  store.commit("setSidebarVisibility", false)
  const length = Object.keys(serversByType.value("cloudServer")).length
  if(length <= 0) {
    createNewServer()
  }
})

router.beforeEach((to, from, next) => {
   if (to.path === '/iaas' || to.path === '/collaboration' || to.path === '/cybercloudendpoint' || to.path === '/container') {
     if (store.getters.isSidebarOpen) {
       const confirm = window.confirm('Wollen Sie die momentane Konfiguration wirklich abbrechen? Nicht gespeicherte Daten gehen verloren!')
       if (!confirm) return next(false) // Abbrechen der Navigation
     }
     store.commit("resetServer")
     store.commit("setSidebarVisibility", false)
   }
   next()
})

//FUNCTIONS
function setSelectedServer(server) {
  store.commit("setSelectedServer", server)
}

function addServer() {
  store.commit("addServer")
}
function deleteServerButton() {
  const length = Object.keys(serversByType.value("cloudServer")).length
  if(length <= 0) {
    createNewServer()
  }
}
function createNewServer() {
  setSelectedServer({
    id: Date.now(),
    name: "Cloud Server",
    type: "cloudServer",
    configuration: {},
  });
  addServer()
}


function changeCreateServerButton() {
  const length = Object.keys(serversByType.value("cloudServer")).length - 1

  if (length >= 0 && Object.keys(serversByType.value("cloudServer")[length].configuration).length === 0) {
    canCreateServer.value = false
  } else {
    canCreateServer.value = true
  }
}


</script>