<template>
  <div class="cloud-select-item">
    <router-link
        :to="{ path: linkName }"
        :class="[
        'row align-items-center',
        {
          'router-link-exact-active router-link-active':
            $route.name === linkName,
        },
      ]"
    >
      <div class="cloud-select-item-img-container">
        <img class="cloud-select-item-img" :src="getImgUrl(src)"/>
        <span class="cloud-select-item-button clickable" :id="myID"></span>
      </div>
      <div class="cloud-select-item-title">
        {{ title }}
      </div>
      <div v-if="countConfigurations > 0" class="cloud-select-item-count">
        <span>{{ countConfigurations }}</span>
      </div>

    </router-link>
  </div>
</template>

<script setup>

//IMPORTS
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import store from '@/store'

//VARIABLES
const route = useRoute()
const colors = ref(['transparent', 'transparent', 'transparent', 'transparent', 'transparent'])

//PROPS
const props = defineProps({
  src: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  linkName: {
    type: String,
    default: () => "/",
  },
  myID: {
    type: Number,
    required: true,
  },
})


//FUNCTIONS
function getImgUrl(pic) {
  return require(`../assets/${pic}`)
}

const checkCheckbox = () => {
  // Setze alle Farben auf transparent zurück
  colors.value.fill('transparent');

  // Überprüfe den Pfad und setze die entsprechende Farbe
  switch (route.path) {
    case '/container':
      colors.value[0] = '#3d7caf';
      store.commit("setWorkingOnOffer", true)
      break;
    case '/cloudServer':
      colors.value[1] = '#3d7caf';
      store.commit("setWorkingOnOffer", true)
      break;
    case '/iaas':
      colors.value[2] = '#3d7caf';
      store.commit("setWorkingOnOffer", true)
      break;
    case '/cybercloudendpoint':
      colors.value[4] = '#3d7caf';
      store.commit("setWorkingOnOffer", true)
      break;
    case '/collaboration':
      colors.value[3] = '#3d7caf';
      store.commit("setWorkingOnOffer", true)
      break;
    case '/prices':
      // Alle bleiben transparent (bereits gesetzt)
      break;
    default:
      break;
  }
}

//WATCH
watch(() => route.path, checkCheckbox)
const serversByType = computed(() => store.getters.serversByType)

const countConfigurations = computed(() => {
  // const type = props.linkName;
  let type = props.linkName;
  if(type === 'collaboration') {
    type = 'wpCloud';
  }
  const serverList = serversByType.value(type)
  if (serverList.length > 0) {
    return serverList.filter(function (obj) {
      return Object.keys(obj.configuration).length > 0
    }).length
  }
  return null
})
checkCheckbox();
</script>


<style lang="scss" scoped>

.cloud-select-item {
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 10px;
  border: none;
  position: relative;

  &-img {
    width: 80px;
  }

  &-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(100%);
  }

  &-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    display: block;
    border: 1px solid #3d7caf;
    border-radius: 5px;
    box-shadow: inset 0 0 3px 0px rgba(0, 0, 0, 0.5);

    &:hover {
      background-color: #3d7caf;
    }
  }

  &-title {
    text-align: center;
    height: 35px;
  }

  a {
    .cloud-select-item-title {
      color: #000;
    }
  }

  a.router-link-exact-active,
  .router-link-active {
    color: #000;

    .cloud-select-item-img-container {
      filter: grayscale(0%);
    }

    .cloud-select-item-button {
      background-color: #3d7caf;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 1px;
        left: 8px;
        width: 6px;
        height: 14px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}

a.router-link-exact-active {
  .cloud-select-item-title {
    color: #000;
  }
}

@media (min-width: 992px) {
  .cloud-select-item {
    &-img {
      width: auto;
      min-width: 120px;
    }

    &-title {
      padding: 0 5px;
    }
  }
}

@media (max-width: 790px) {
  .cloud-select-item {
    &-title {
      height: 60px;
    }
  }
}

@media (max-width: 490px) {
  .cloud-select-item {
    &-title {
      height: 60px;
    }
  }
}
.cloud-select-item-count {
  position: absolute;
  right: -4px;
  height: 20px;
  width: 20px;
  background: #CF2B29;
  font-size: 13px;
  border-radius: 400px;
  color: #ffff;
  bottom: -3px;
}
.clickable {
  cursor: pointer;

  &:hover {
    color: #D02C2A;
  }
}

</style>
