<template>
  <div>
    <div v-for="(server, index) in container" :key="index">
      <Container 
        :serverId="server.id" 
        :isLast="index === container.length - 1" 
        @addServer="createNewContainer" 
        :canCreateContainer="canCreateContainer" 
        @changeCreateContainerButton="changeCreateContainerButton"
        @deleteServerButton="createContainerIfEmpty"
      />
    </div>
  </div>
</template>

<script setup>

//IMPORTS
import { ref, computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import Container from "@/components/server/Container.vue"
import store from '@/store'
import CloudServer from "@/components/server/CloudServer.vue";

//VARIABLES
const canCreateContainer = ref(true)
const router = useRouter()

//COMPUTED PROPERTIES
const serversByType = computed(() => store.getters.serversByType)
const container = computed(() => serversByType.value("container"))

//LIFECYCLE HOOKS
onMounted(() => {
  store.commit("setSidebarVisibility", false)
  createContainerIfEmpty()
})

router.beforeEach((to, from, next) => {
   if (to.path === '/iaas' || to.path === '/collaboration' || to.path === '/cybercloudendpoint' || to.path === '/cloudServer') {
     if (store.getters.isSidebarOpen) {
       const confirm = window.confirm('Wollen Sie die momentane Konfiguration wirklich abbrechen? Nicht gespeicherte Daten gehen verloren!')
       if (!confirm) return next(false)
     }
     store.commit("resetServer")
     store.commit("setSidebarVisibility", false)
   }
   next()
})

//FUNCTIONS
function setSelectedServer(server) {
  store.commit("setSelectedServer", server)
}

function addServer() {
  store.commit("addServer")
}
function createNewContainer() {
  setSelectedServer({
    id: Date.now(),
    name: "Container",
    type: "container",
    configuration: {},
  });
  addServer()
}

function createContainerIfEmpty() {
  if (container.value.length === 0) {
    createNewContainer()
  }
}

function changeCreateContainerButton() {
  const length = Object.keys(serversByType.value("container")).length - 1

  if (length >= 0 && Object.keys(serversByType.value("container")[length].configuration).length === 0) {
    canCreateContainer.value = false
  } else {
    canCreateContainer.value = true
  }
}

</script>