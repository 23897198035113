<template>
  <div class="iaas-container">
      <div class="router-container">
        <img v-if="firewall" class="firewall-shield" src='../assets/active-ngf.svg'/>
        <img v-else class="firewall-shield" src='../assets/inactive-ngf.svg'/>
        <div class="connection horizontal"></div>
        <div class="connection vertical"></div>
        <img v-if="routerVersion >= 768" class="router" src='../assets/router-calculator.svg'/>
        <img v-else class="router" src='../assets/router-calculator.svg'/>
        <div class="NgfAndIps">
          <div id="switch" style="margin-bottom: 10px; display: flex; flex-direction: row;">
            <div >
              <a-switch v-model:checked="firewallStatus"></a-switch>
            </div>
            <div style=" display: flex; flex-direction: row; flex-wrap: wrap;">
              <span style="margin-left: 5px;">Next-Generation-Firewall {{ firewall ? "ausschalten" : "einschalten" }}&nbsp;
                <a-popover title="Hinweis"
                  icon="info-circle"
                  id="hint">
                    <template #content>
                      <p style="font-size: 10px; width: 200px;">{{ 'Die Next-Generation-Firewall ist ein mehrstufiges Sicherheitssystem zum Schutz vor modernen Cyberattacken' }}</p>
                    </template>
                    <ExclamationCircleOutlined />
                </a-popover>
              </span>
            </div>
          </div>
          <div id="ips">
            <a-input
              style="width: 70px; margin-right: 5px;"
              type="number"
              width="200"
              number
              id="ipAddressNumber"
              v-model:value="ipAddress"
              :min="1"
              :max="200000"
            ></a-input>
            <span v-html="'Öffentliche IP-Adressen'"></span>&nbsp;
            <a-popover title="Hinweis"
              icon="info-circle"
              id="hint">
              <template #content>
                <p style="font-size: 10px; width: 200px;">{{ 'Die erste IP-Adresse ist kostenlos. Jede weitere IP-Adresse kostet 10,00€' }}</p>
              </template>
              <ExclamationCircleOutlined />
            </a-popover>
          </div>
        </div>
      </div>
      
    <IaaSNetwork
      v-for="(net, i) in networks"
      :network="net"
      :key="i"
      :last="i === networks.length - 1"
      v-on:deleteNetworkModal="deleteNetworkModal"
    />
    <div class="add-network-container">
      <div class="kdz-bg-grey clickable createNetwork" @click="addNetwork">
        <div class="connection hor-next-net"></div>
        <div class="connection vert-next-net"></div>
          <img
            style="padding-left: 5px;"
            class="buttonImage"
            width="20"
            src='../assets/button_square.svg'/>&nbsp;
        <span class="createNewNetwork"> Neues Netzwerk erstellen </span>
      </div>
      <div class="noticeText">
        <small>Hinweis: Die Kostenrechnung basiert auf der tatsächlichen Laufzeit in Stunden: Festplatten und Lizenzen werden während der gesamten VM-Existenz abgerechnet, selbst im ausgeschalteten Zustand, während CPU und RAM nur für die aktive Betriebszeit berechnet werden.</small>
      </div>
    </div>
    <a-modal
        :title="'Netzwerk löschen?'"
        v-model:open="deleteNetworkModalBool"
        :closable="true"
        @cancel="deleteNetworkModalBool = false"
        @ok="deleteNetwork()"
        okText="Ok"
        cancelText="Abbrechen"
        centered>
        Wollen Sie das Netzwerk wirklich löschen? Alle konfigurierten Server in diesem Netzwerk werden ebenfalls gelöscht!
      </a-modal>
  </div>
</template>

<script setup>

//IMPORTS
import { ref, computed, onMounted } from 'vue'
import IaaSNetwork from "@/components/server/IaaSNetwork.vue"
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import store from '@/store'

//VARIABLES
const deleteNetworkModalBool = ref(false)
const deleteThisNetwork = ref(0)
const router = useRouter()

//COMPUTED PROPERTIES
const firewall = computed(() => store.getters.firewall)
const networkList = computed(() => store.getters.networkList)
const ip = computed(() => store.getters.ip)

const networks = computed({
  get() {
    return networkList.value;
  },
  set(value) {
    pushNetworkList(value);
  }
})

const ipAddress = computed({
  get() {
    return ip.value
  },
  set(value) {
    setIp(value)
  }
})

const firewallStatus = computed({
  get() {
    return firewall.value
  },
  set(value) {
    setFirewall(value)
  }
})

const routerVersion = computed(() => window.screen.width)

//LIFECYCLE HOOKS
onMounted(() => {
  store.commit("setSidebarVisibility", false)
  ipAddress.value = ipAddress.value === 0 ? 1 : ipAddress.value
  fetchConfiguredNetworks()
})

router.beforeEach((to, from, next) => {
  if (to.path === '/cloudServer' || to.path === '/collaboration' || to.path === '/cybercloudendpoint' || to.path === '/container') {
    if (store.getters.isSidebarOpen) {
      const confirm = window.confirm('Wollen Sie die momentane Konfiguration wirklich abbrechen? Nicht gespeicherte Daten gehen verloren!')
      if (!confirm) return next(false)
    }
    store.commit("resetServer")
    store.commit("setSidebarVisibility", false)

    if (store.getters.bill.filter(x => x.type === 'iaas').length === 0) {
      store.commit("setIp", 0)
    }
  }
  next()
})

//FUNCTIONS
function setFirewall(value){
  store.commit('setFirewall', value)
}

function setIp(value){
  store.commit('setIp', value)
}

function pushNetworkList(value) {
  store.commit('pushNetworkList', value)
}

function addNetwork() {
  let last = networks.value[networks.value.length - 1]
  last++
  networks.value = last
}

function deleteNetwork() {
  const tempNetworks = [...networks.value]
  const index = tempNetworks.indexOf(deleteThisNetwork.value)

  if (index > -1) {
    tempNetworks.splice(index, 1)
    store.commit('setNetworkList', tempNetworks)
    store.commit('deleteNetworkServer', deleteThisNetwork.value)
    store.commit('setSidebarVisibility', false)
    store.commit('resetServer')

    if (tempNetworks.length === 0) {
      networks.value = [1]
    }

    deleteNetworkModalBool.value = false
  }
}

function deleteNetworkModal(networkId) {
  deleteThisNetwork.value = networkId
  deleteNetworkModalBool.value = true
}

async function fetchConfiguredNetworks() {
   const networkServer = store.getters.serverList.filter(x => x.type === 'iaas' || 'containerIaaS')
   if (networkServer.length > 1) {
     for (let netServer of networkServer) {
       const netNr = netServer.configuration.network;
       if (!networks.value.includes(netNr)) {
         networks.value.push(netNr)
       }
     }
   }
}
</script>

<style lang="scss" scoped>
.clickable {
    cursor: pointer;
  
    &:hover {
      color: #d02c2a;
    }
  }
.createNetwork {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
}
.kdz-bg-grey {
  background-color: rgba(135, 135, 135, 0.11);
  position: relative;
  border-radius: 10px;
  margin-right: 20px;
}
.NgfAndIps {
  text-align: left;
}
.iaas-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.router {
  width: 100%;
  position: relative;
  z-index: 2;
  left: -25px;
  &-container {
    padding: 0 1rem;
    margin-bottom: 20px;
  }
}

.firewall-shield {
  position: absolute;
  z-index: 3;
  width: 28px;
  top: 0;
  left: 32%;
}

.add-network-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: auto;
  text-align: left;
  margin: 1rem 0.25rem 1rem 0.25rem;
}

.runtime-hint {
  background-color: rgba(135, 135, 135, 0.11);
  border-radius: 10px;
  margin: 0 3rem;
  padding: 10px;
}

 @media (min-width: 1161px) {
  .router {
    width: 40%;
    &-container {
      padding: 0 3rem;
      display: flex;
      flex-direction: row;
      padding-left: 75px;
    }
  }
  .firewall-shield {
    top: 28px;
  }

  .add-network-container {
    margin: 1rem 0 1rem 3.5rem;
  }

  .connection {
    position: absolute;
    background-color: #BEBEBE;
    z-index: 1;
    &.horizontal {
      top: 44px;
      left: 6px;
      width: 60px;
      height: 10px;
    }
    &.vertical {
      top: 44px;
      left: 0px;
      width: 10px;
      height: 136px;
    }
    &.hor-next-net {
      top: 28px;
      left: -54px;
      width: 55px;
      height: 10px;
    }
    &.vert-next-net {
      top: -16px;
      left: -3.5rem;
      width: 10px;
      height: 54px;
    }
  }
} 

@media (max-width: 1200px) {
  .router {
    width: 40%;
    &-container {
      padding: 0 3rem;
      display: flex;
      flex-direction: row;
    }
  }
  .firewall-shield {
    top: 33px;
    left: 29%;
  }
  .NgfAndIps {
    text-align: left;
    width: 300px;
    font-size: 90%;
  }

  .add-network-container {
    margin: 1rem 3rem;
    width: 560px;
    height: 50px;
  }

  .connection {
    position: absolute;
    background-color: #BEBEBE;
    z-index: 1;
    &.horizontal {
      top: 44px;
      left: 0px;
      width: 40px;
      height: 10px;
    }
    &.vertical {
      top: 54px;
      left: 0px;
      width: 10px;
      height: 136px;
    }
    &.hor-next-net {
      top: 23px;
      left: -3rem;
      width: 3rem;
      height: 10px;
    }
    &.vert-next-net {
      top: -21px;
      left: -3rem;
      width: 10px;
      height: 54px;
    }
  }
  .createNewNetwork {
    font-size: 90%;
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .noticeText {
    //font-size: 80%;
  }
  .buttonImage {
    margin-left: 5px;
  }
}
@media (max-width: 930px) {

  /*.router {
    left: -42px;
    top: 16px;
  }*/
  .NgfAndIps {
    text-align: left;
    width: 180%;
    font-size: 90%;
  }

  .router {
    top: 12px;
    width: 105px;
    &-container {
      padding: 0 0 0 3rem;
      display: flex;
      flex-direction: row;
    }
  }
  .firewall-shield {
    left: 127px;
    width: 24px;
    top: 40px;
  }
  .add-network-container {
    width: 445px;
    margin: 0 1rem;
  }
  .connection {
    &.hor-next-net {
      width: 16px;
      left: -16px;
    }
    &.vert-next-net {
      left: -16px;
    }
  }
}

@media (max-width: 790px) {
  .add-network-container {
    grid-template-columns: 1fr;
    height: auto;
  }
.createNewNetwork {
  width: auto;
}
.iaas-container {
    margin-bottom: 35px;
  }
}

@media (max-width: 490px) {
  .add-network-container {
    width: 360px;
  }
  .add-network-container {
   flex-direction: column;
    margin: 0 1rem;
  }
  .iaas-container {
    margin-bottom: 50px;
  }
  .noticeText {
    font-size: 80%;
    position: relative;
    left: -15px;
  }
  /*.router {
    top: -3px;
    left: -47px;
  }*/
  .firewall-shield {
    left: 100px;
    width: 20px;
    top: 50px;
  }
}
</style>