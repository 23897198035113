<template>
    <div style="display: flex; flex-direction: column;">
        <div class="inputContainer" id ="isResourceInputContainer">
            <div style="display: flex; flex-direction: row;">
                <span class="textField" style="margin-right: 3px;">Replicas</span>
                <a-tooltip placement="right">
                    <template #title>{{ 'Wählen Sie die gewünschte Anzahl an Replicas. Die Menge der CPU Kerne und GB an RAM werden mit der Anzahl der Replicas multipliziert.' }}</template>
                    <ExclamationCircleOutlined />
                </a-tooltip>
            </div>
            <a-input-number :class="['inputField', { inputFieldRequired: (setRequireField('Replica') && submitButtonClicked) ? true : false }]" :min="1" :max="5" v-model:value="Replicas"></a-input-number>
        </div>
        <div class="inputContainer">
            <span class="textField">Container vCPU (3,2GHz)</span>
            <a-input-number :class="['inputField', { inputFieldRequired: (setRequireField('CPU') && submitButtonClicked) ? true : false }]" :min="1" :max="999" v-model:value="CPU"></a-input-number>
        </div>
        <div class="inputContainer">
            <span class="textField">Container RAM in GB</span>
            <a-input-number :class="['inputField', { inputFieldRequired: (setRequireField('RAM') && submitButtonClicked) ? true : false }]" :min="1" :max="9999" v-model:value="RAM"></a-input-number>
        </div>
        <div class="inputContainer">
            <span class="textField">Container Storage in GB</span>
            <a-input-number :class="['inputField', { inputFieldRequired: (setRequireField('Storage') && submitButtonClicked) ? true : false }]" :min="1" :max="99999" v-model:value="Disk"></a-input-number>
        </div>
    </div>
</template>

<script setup>

//IMPORTS
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import {  computed } from 'vue'
import store from '@/store'

//PROPS
const props = defineProps({
    requireReplica: {
        type: Boolean,
        required: false
    },
    requireCpu: {
        type: Boolean,
        required: false
    },
    requireRam: {
        type: Boolean,
        required: false
    },
    requireStorage: {
        type: Boolean,
        required: false
    },
    submitButtonClicked: {
        type: Boolean,
        required: false
    },
})

//COMPUTED PROPERTIES
const selectedServer = computed(() => store.getters.selectedServer);

const Replicas = computed({
  get() {
    if (selectedServer.value.configuration["Replicas"] === undefined) {
      updateConfig("Replicas", 1);
      return 1
    }
    return selectedServer.value.configuration["Replicas"]
  },

  set(value) {
    if (value > 5) {
      value = 5
    } else if (value < 1) {
      value = 1
    }
    updateConfig("Replicas",  parseInt(value));
  },
})

const CPU = computed({
  get() {
    if (selectedServer.value.configuration["container_cpu"] === undefined || selectedServer.value.configuration["container_cpu"] < 1) {
      updateConfig("container_cpu", 1);
      return 1
    }
    return selectedServer.value.configuration["container_cpu"]
  },

  set(value) {
    if (value > 999) {
        value = 999
    } else if (value < 1) {
        value = 1
    }
    updateConfig("container_cpu",  parseInt(value));
  }
})
 
const RAM = computed({
  get() {
    if (selectedServer.value.configuration["container_memory"] === undefined || selectedServer.value.configuration["container_memory"] < 1) {
        updateConfig("container_memory", 1);
        return 1
    }
    return selectedServer.value.configuration["container_memory"]
  },

  set(value) {
    if (value > 9999) {
        updateConfig("container_memory", 9999);
    } else if (value < 1) {
        updateConfig("container_memory", 1);
    } else {
        updateConfig("container_memory",  parseInt( parseInt(value)));
    }
   }
})

const Disk = computed({
  get() {
    if (selectedServer.value.configuration["container_storage"] === undefined || selectedServer.value.configuration["container_storage"] < 1) {
       updateConfig("container_storage", 1);
        return 1
    }
    return selectedServer.value.configuration["container_storage"]
   },

    set(value) {
      if (value > 99999) {
        updateConfig("container_storage", 99999);
      } else if (value < 1) {
        updateConfig("container_storage", 1);
      } else {
        updateConfig("container_storage",  parseInt(value));
      }
    }
}) 

//FUNCTIONS
function setSelectedConfiguration (configuration) {
  store.commit('setSelectedConfiguration', configuration)
}

function updateConfig(attribute, value) {
  let server = selectedServer.value;
    server.configuration[attribute] = value;
    setSelectedConfiguration(server.configuration);
}

function setRequireField(input) {
  if (input === 'CPU') {
    return props.requireCpu
  } else if (input === 'RAM') {
    return props.requireRam
  } else if (input === 'Storage') {
    return props.requireStorage
  } else if (input === 'Replica') {
    return props.requireReplica
  }
}
</script>

<style>
.inputField {
    width: 60px;
}
.textField {
    display: flex;
    align-items: center;
}
.inputContainer {
    display: flex; 
    flex-direction: row; 
    justify-content: space-between; 
    padding: 2px;
}
.inputFieldRequired {
  width: 60px;
  margin-top: 5px;
  border-color: red;
  border-width: 2px;
}
</style>